import axiosInstance from "./apiService";

const GuestService = {
  getAccessToken(api: string): Promise<string> {
    return axiosInstance().get(
      `${api}/v1/Guest/Token?code=${process.env.REACT_APP_GUEST_FUNCTION_KEY}`
    );
  },
};

export default GuestService;
