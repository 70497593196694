import React from "react";
import { ContainerRect } from "yet-another-react-lightbox";

type Props = {
  src: string;
  rect: ContainerRect;
};

export default function Microsite({ src, rect }: Props) {
  return (
    <iframe
      src={src}
      width={rect.width}
      height={rect.height}
      title="Pleasant Company Hub Micro Site"
    />
  );
}
