import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Instance } from "./models/Instance";
import curiiousConfig from "../curiiousConfig.json";
import { Convert, ViewState } from "./models/ViewState";
import { CuriiousUser, UserUtils } from "./models/CuriiousUser";
import GuestService from "../services/guestService";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode, { JwtPayload } from "jwt-decode";

//Create Management Types
type ApiContextType =
  | {
      label: "production" | "staging" | "local";
      url: string;
    }
  | undefined;
type InstanceContextType = {
  setInstanceState: React.Dispatch<React.SetStateAction<Instance | undefined>>;
  instanceState: Instance | undefined;
  helpVisibility: "minimized" | "maximized" | "hidden";
  setHelpVisibility: React.Dispatch<
    React.SetStateAction<"minimized" | "maximized" | "hidden">
  >;
};
type CuriiousUserContextType = {
  setCuriiousUserState: React.Dispatch<
    React.SetStateAction<CuriiousUser | undefined>
  >;
  curiiousUserState: CuriiousUser | undefined;
  getApiToken(): Promise<string>;
};
type WorldContextType = {
  setWorldState: React.Dispatch<React.SetStateAction<number | undefined>>;
  worldState: number | undefined;
};
type ViewStateContextType = {
  setViewState: React.Dispatch<React.SetStateAction<ViewState | undefined>>;
  viewState: ViewState | undefined;
};

const ApiContext = createContext<ApiContextType>(undefined);
const InstanceContext = createContext<InstanceContextType>(
  {} as InstanceContextType
);
const CuriiousUserContext = createContext<CuriiousUserContextType>(
  {} as CuriiousUserContextType
);
const BuildContext = createContext<number>(0);
const WorldContext = createContext<WorldContextType | undefined>(undefined);
const ViewStateContext = createContext<ViewStateContextType | undefined>(
  undefined
);

type Props = {
  children?: React.ReactNode;
};

function CuriiousStateContextProvider({ children }: Props) {
  const [api, setApi] = useState<ApiContextType | undefined>(undefined);
  const [buildId, setBuildId] = useState<number>(0);
  const [instanceState, setInstanceState] = useState<Instance | undefined>(
    undefined
  );
  const [curiiousUserState, setCuriiousUserState] = useState<
    CuriiousUser | undefined
  >(undefined);
  const [guestApiToken, setGuestApiToken] = useState<string>("");
  //World is currently just the id, be we probably want to pass the full world object eventually.
  const [worldState, setWorldState] = useState<number | undefined>(undefined);
  const [viewState, setViewState] = useState<ViewState | undefined>(undefined);
  const [helpVisibility, setHelpVisibility] = useState<
    "minimized" | "maximized" | "hidden"
  >("minimized");

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  function GenerateSearchParams(): URLSearchParams {
    var returnParameters: URLSearchParams = new URLSearchParams(
      window.location.search
    );

    if (viewState) {
      //Add the view state, if it's a thing we're tracking.
      returnParameters.set(
        "view",
        encodeURIComponent(JSON.stringify(viewState))
      );
    }

    if (buildId !== 0) {
      //Same if the buildid is defined.
      returnParameters.set("buildid", buildId.toString());
    }

    if (api && api.label !== "production") {
      returnParameters.set("api", api.label);
    }

    return returnParameters;
  }

  function UpdateCurrentUrl(): void {
    //When state changes, we need to refresh the url to reflect it.

    if (instanceState) {
      if (!worldState || worldState === 0) {
        //No world state is stored.
        navigate(
          {
            pathname: `${instanceState.displayName}`,
            search: GenerateSearchParams().toString(),
          },
          { replace: false }
        );
      } else {
        navigate(
          {
            pathname: `${instanceState.displayName}/world/${worldState}`,
            search: GenerateSearchParams().toString(),
          },
          { replace: false }
        );
      }
    }
  }

  useEffect(() => {
    //Run once when the page loads.
    console.log("Initializing Curiious Event Platform.");

    const searchParams = new URLSearchParams(window.location.search);

    // Store the api. Assume anything other than staging or local is prod.
    const apiFromParams = searchParams.get("api");
    if (apiFromParams === "staging") {
      console.log(`Setting custom API to ${apiFromParams}`);
      setApi({ label: "staging", url: curiiousConfig.apiUrl.staging });
    } else if (apiFromParams === "local") {
      console.log(`Setting custom API to ${apiFromParams}`);
      setApi({ label: "local", url: curiiousConfig.apiUrl.local });
    } else {
      console.log(`Setting production API`);
      setApi({ label: "production", url: curiiousConfig.apiUrl.production });
    }

    // Store the build id
    const buildIdFromParams = searchParams.get("buildid");
    if (buildIdFromParams && !buildId) {
      console.log(
        `Build ID (${buildIdFromParams}) found in URL parameters. Storing it!`
      );
      setBuildId(Number(buildIdFromParams));
    }

    // Also look for and store the player transforms.
    const viewStateFromParams = searchParams.get("view");
    if (viewStateFromParams && !viewState) {
      console.log("View State found in URL parameters. Storing it!");

      //Deserialize it and store it.
      setViewState(
        Convert.toViewState(decodeURIComponent(viewStateFromParams))
      );
    }
    // eslint-disable-next-line
  }, []);

  //This effect tracks changes to URL parameters
  useEffect(() => {
    //In theory this should only run when the first one changes.
    if (api || buildId || viewState) {
      console.log("Parameter State Changed.");
      UpdateCurrentUrl();
      // setSearchParams(GenerateSearchParams());

      // navigate(
      //     { pathname: `${instanceState?.displayName}`, search: GenerateSearchParams().toString() },
      //     { replace: false }
      // )
    }
    // eslint-disable-next-line
  }, [api, buildId, viewState]);

  //This effect tracks changes to elements in the main url
  useEffect(() => {
    // console.log("State manager says a state we're tracking was updated.");

    //Let's update the url in the url bar. We effectively need to rebuild this
    //every time one of our tracked states updates.

    //For convenience, we want to preserve the url parameters if there are any.
    // var queryString = window.location.search;

    //searchParams.
    // searchParams()
    if (instanceState) {
      if (!worldState || worldState === 0) {
        //No world state is stored.
        navigate(
          {
            pathname: `${instanceState.displayName}`,
            search: GenerateSearchParams().toString(),
          },
          { replace: false }
        );
      } else {
        navigate(
          {
            pathname: `${instanceState.displayName}/world/${worldState}`,
            search: GenerateSearchParams().toString(),
          },
          { replace: false }
        );
      }
    }
    // eslint-disable-next-line
  }, [instanceState, worldState]);

  async function getApiToken(): Promise<string> {
    //This function is polluting this context - look to move all the context types to their own file
    //for santiy's sake.

    //This code gets the guest code, and refreshes it if it's out of date.
    if (curiiousUserState && api?.url) {
      //We handle the token request differently if we are a guest or registered user.
      if (UserUtils.isGuest(curiiousUserState)) {
        //We are a guest.
        // console.log("Requesting guest token");
        var refresh: boolean = false;

        if (guestApiToken) {
          //There's a token, we need to check if it's still valid.
          const decoded = jwtDecode<JwtPayload>(guestApiToken);
          const currentTimestampUTC = Math.floor(Date.now() / 1000);

          //Compare timestamps.
          refresh = decoded.exp! < currentTimestampUTC;
        } else {
          //No token. We need one.
          refresh = true;
        }

        if (refresh) {
          //We need to refresh the token!
          // console.log("grabbing new token");
          let tokenResponse = await GuestService.getAccessToken(api.url);
          setGuestApiToken(tokenResponse);
          return tokenResponse;
        } else {
          //Send back the existing one.
          // console.log("sending existing token");
          // console.log(guestApiToken);
          return guestApiToken;
        }
      } else {
        //We're a registered user, so we can just do the auth0 thing.
        return await getAccessTokenSilently();
      }
    }

    return "";
  }

  return (
    // the Provider gives access to the context to its children
    <ApiContext.Provider value={api}>
      <BuildContext.Provider value={buildId}>
        <InstanceContext.Provider
          value={{
            instanceState,
            setInstanceState,
            helpVisibility,
            setHelpVisibility,
          }}
        >
          <CuriiousUserContext.Provider
            value={{
              setCuriiousUserState: setCuriiousUserState,
              curiiousUserState: curiiousUserState,
              getApiToken: getApiToken,
            }}
          >
            <WorldContext.Provider value={{ worldState, setWorldState }}>
              <ViewStateContext.Provider value={{ viewState, setViewState }}>
                {children}
              </ViewStateContext.Provider>
            </WorldContext.Provider>
          </CuriiousUserContext.Provider>
        </InstanceContext.Provider>
      </BuildContext.Provider>
    </ApiContext.Provider>
  );
}

export {
  ApiContext,
  BuildContext,
  InstanceContext,
  CuriiousUserContext,
  WorldContext,
  ViewStateContext,
  CuriiousStateContextProvider,
};
