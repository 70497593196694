import React, { Fragment } from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import ErrorMessage from "../ErrorMessage";
import { ContainerRect } from "yet-another-react-lightbox";

type Props = {
  htmlString: string;
  rect: ContainerRect;
};

export default function HtmlSnippet({ htmlString, rect }: Props) {
  //First, clean the string to ensure we're not rendering anything nasty.
  const clean = DOMPurify.sanitize(htmlString, { ADD_TAGS: ["iframe"] });
  if (clean) {
    var parsedHtml = parse(htmlString);

    //Determine if we need to wrap this element in a div for it to display properly.
    var useWrapper: boolean = false;
    console.log("there are: " + (parsedHtml as JSX.Element[]).length);
    if ((parsedHtml as JSX.Element[]).length) {
      //There's more than one element. We should wrap it.
      useWrapper = true;
    } else {
      //There's only one element. if it's a div or an iframe, we can let it go as is.
      //Otherwise we wrap.
      parsedHtml = parsedHtml as JSX.Element;
      if (parsedHtml.type === "div" || parsedHtml.type === "iframe") {
        //This is a div or an iframe. That's good!!
        //We are assuming width properties are passed into these, so we don't wrap them.
        //TODO: Expose more functionality as we establish more use cases.
        console.log("Parent element is div or iframe. Wrapper not created");
      } else {
        console.log("we dont have iframe or div");
        useWrapper = true;
      }
    }

    if (useWrapper) {
      return (
        <div
          id="Snippet Wrapper"
          style={{
            width: rect.width,
            height: rect.height,
            backgroundColor: "white",
          }}
        >
          {parsedHtml}
        </div>
      );
    } else {
      return <Fragment>{parsedHtml}</Fragment>;
    }
  } else {
    return (
      <ErrorMessage
        errorType={"error"}
        errorText={"Unable to load content"}
        centreOnPage={false}
      />
    );
  }
}
