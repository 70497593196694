import React, { useContext } from "react";
import { ApiContext } from "./CuriiousStateContextProvider";
import CuriiousInstance from "./CuriiousInstance";
import LoadingSpinner from "./LoadingSpinner";

export default function CuriiousStateGate() {
  //This component looks at the curiious state context, and prevents its children from rendering
  //unless the state has been calculated. There's probably a better way to do this, but it solved
  //a bug I spent days on.

  const apiContext = useContext(ApiContext);

  if (apiContext) {
    return <CuriiousInstance />;
  } else {
    return <LoadingSpinner heightVh={100} />;
  }
}
