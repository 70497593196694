import React, { useCallback, useEffect, useState } from "react";
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import Lightbox, {
  HtmlSnippetSlide,
  MicrositeSlide,
  Render,
  Slide,
  YoutubeSlide,
} from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
// eslint-disable-next-line
import Captions from "yet-another-react-lightbox/plugins/captions";
import HtmlSnippet from "./LightboxSlides/HtmlSnippet";
import Microsite from "./LightboxSlides/Microsite";
import Youtube from "./LightboxSlides/Youtube";
import { UnityContextHook } from "react-unity-webgl/distribution/types/unity-context-hook";

declare module "yet-another-react-lightbox" {
  export interface HtmlSnippetSlide extends GenericSlide {
    id: string;
    type: "html-snippet";
    htmlString: string;
    title: string;
    description: string;
  }
  export interface MicrositeSlide extends GenericSlide {
    id: string;
    type: "microsite";
    src: string;
    title: string;
    description: string;
  }
  export interface YoutubeSlide extends GenericSlide {
    id: string;
    type: "youtube";
    src: string;
    title: string;
    description: string;
  }
  interface SlideTypes {
    "html-snippet": HtmlSnippetSlide;
    microsite: MicrositeSlide;
    youtube: YoutubeSlide;
  }
}

type Props = {
  unityContextHook: UnityContextHook;
};

//This is the main wrapper function for ourlightbox
export default function CuriiousLightbox({ unityContextHook }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [slides, setSlides] = useState<Slide[]>();

  //Register listeners
  useEffect(
    () => {
      console.log("lightbox effect...");
      unityContextHook.addEventListener("ShowLightbox", handleShowLightbox);
      return () => {
        unityContextHook.removeEventListener(
          "ShowLightbox",
          handleShowLightbox
        );
      };
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const handleShowLightbox = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      console.log("Showing lightbox...");
      const params = unityParam as any;

      switch (params.elementType) {
        case "YoutubeVideo":
          console.log("YT Video");
          const ytSlide: YoutubeSlide = {
            id: params.id,
            type: "youtube",
            src: params.src,
            title: params.title,
            description: params.description,
          };
          setSlides([ytSlide]);
          break;
        case "MicroSite":
          console.log("Microsite");

          //We need to determine if we're linking straight to an image. If so, we handle
          //this slightly differently. Potentially look to add an "Image" element type
          //in the future to handle.

          if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(params.src)) {
            //An image. We use a normal slide
            var micrositeImgSlide: Slide = {
              id: params.id,
              src: params.src,
              title: params.title,
              description: params.description,
            };
            setSlides([micrositeImgSlide]);
          } else {
            //Not an image
            var msSlide: MicrositeSlide = {
              id: params.id,
              type: "microsite",
              src: params.src,
              title: params.title,
              description: params.description,
            };
            setSlides([msSlide]);
          }
          break;
        case "HtmlSnippet":
          console.log("Html Snippet");
          var htmlSlide: HtmlSnippetSlide = {
            id: params.id,
            type: "html-snippet",
            htmlString: params.src,
            title: params.title,
            description: params.description,
          };
          setSlides([htmlSlide]);
          break;
        case "Image":
          console.log("Image Element");
          //An image. We use a normal slide
          var imageSlide: Slide = {
            id: params.id,
            src: params.src,
            title: params.title,
            description: params.description,
          };
          setSlides([imageSlide]);
          break;
        default:
          console.log("Unsupported Element Type");
          return;
      }

      setOpen(true);
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const handleCloseLightbox = useCallback(() => {
    console.log("Lightbox closed");
    setOpen(false);
    unityContextHook.sendMessage("WorldManager", "FadeBackgroundAudioUp");
  }, [unityContextHook]);

  //The lightbox renderer determines the look of the lightbox,
  //and how it displays custom slide types.
  const lightboxRenderer: Render = {
    slide: (slideProps) => {
      if (slideProps.slide.type === "youtube") {
        return (
          <Youtube videoUrl={slideProps.slide.src} rect={slideProps.rect} />
        );
      } else if (slideProps.slide.type === "html-snippet") {
        return (
          <HtmlSnippet
            htmlString={slideProps.slide.htmlString}
            rect={slideProps.rect}
          />
        );
      } else if (slideProps.slide.type === "microsite") {
        return <Microsite src={slideProps.slide.src} rect={slideProps.rect} />;
      } else {
        return undefined;
      }
    },
    //Turn off the next and previous buttons. We dont need them for the moment.
    buttonNext: () => {
      return undefined;
    },
    buttonPrev: () => {
      return undefined;
    },
  };

  return (
    <Lightbox
      // plugins={[Captions]}
      open={open}
      close={handleCloseLightbox}
      animation={{ fade: 0 }} //This works around a bug where we see the fade twice. TODO: Fix the bug!
      slides={slides}
      render={lightboxRenderer}
      carousel={{
        finite: true,
        padding: 85,
      }}
      controller={{
        closeOnBackdropClick: true,
        closeOnPullDown: true,
      }}
      styles={{
        container: {
          backgroundColor: "rgba(0, 0, 0, .85)",
        },
      }}
    />
  );
}
