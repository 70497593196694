import React, { useEffect, useState } from "react";
import "./components/styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Outlet, Route, Routes } from "react-router-dom";
import WelcomePage from "./components/WelcomePage";
import curiiousConfig from "./curiiousConfig.json";
import Auth0Redirect from "./components/eventPlatforms/Auth0Redirect";
import { Convert, DowntimeHandler } from "./components/models/DowntimeHandler";
import SiteDown from "./components/SiteDown";
import ReactGA from "react-ga4";
import WorldLoader from "./components/WorldLoader";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CuriiousStateContextProvider } from "./components/CuriiousStateContextProvider";
import CuriiousStateGate from "./components/CuriiousStateGate";
import CaptureDetails from "./components/CaptureDetails";
import { isMobile } from "react-device-detect";

function App() {
  const [downtimeHandler, setDowntimeHandler] = useState<DowntimeHandler>();

  // Route mobile users to the beta site
  if (isMobile) {
    window.location.replace(
      `https://beta.curiious.com${window.location.pathname}${window.location.search}`
    );
  }

  //Look at the url parameters, and swap auth0 tennants if necessary.
  const authTennant = new URLSearchParams(window.location.search).get("auth");
  var authRedirect = curiiousConfig.auth0.production.redirect;
  if (authTennant === "dev") {
    authRedirect = curiiousConfig.auth0.development.redirect;
  }

  const getDowntimeHandler = () => {
    fetch(process.env.PUBLIC_URL + "/downtimeHandler.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        pragma: "no-cache",
        "cache-conctrol": "no-cache",
      },
    })
      .then(function (response) {
        return response.text();
      })
      .then(function (responseText) {
        setDowntimeHandler(Convert.toDowntimeHandler(responseText));
      });
  };

  useEffect(() => {
    // Set up the GTAG
    console.log("Setting up GTAG");
    if (window.location.origin === curiiousConfig.deployUrl.production) {
      console.log("Production Tag Set");
      ReactGA.initialize(curiiousConfig.googleAnalytics.production);
    } else {
      console.log("Staging/Dev Tag Set");
      ReactGA.initialize(curiiousConfig.googleAnalytics.staging);
    }

    //Look for the downtime config file all the time.
    getDowntimeHandler();
  }, []);

  //If the downtimeconfig file is turned on, show that content.
  //Otherwise, procede as normal.
  if (downtimeHandler && downtimeHandler.isDown) {
    return (
      <div className="App">
        <SiteDown downtimeHandler={downtimeHandler} />
      </div>
    );
  } else {
    return (
      <HelmetProvider>
        <Helmet>
          <title>{curiiousConfig.meta.applicationName}</title>
          <meta
            name="description"
            content={curiiousConfig.meta.applicationDescription}
          />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemProp="name" content={curiiousConfig.meta.applicationName} />
          <meta
            itemProp="description"
            content={curiiousConfig.meta.applicationDescription}
          />
          <meta itemProp="image" content="" />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content="https://hub.curiious.com" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={curiiousConfig.meta.applicationName}
          />
          <meta
            property="og:description"
            content={curiiousConfig.meta.applicationDescription}
          />
          <meta property="og:image" content="" />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={curiiousConfig.meta.applicationName}
          />
          <meta
            name="twitter:description"
            content={curiiousConfig.meta.applicationDescription}
          />
          <meta name="twitter:image" content="" />
        </Helmet>
        <div className="App">
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path={authRedirect} element={<Auth0Redirect />} />
            <Route
              element={
                <CuriiousStateContextProvider>
                  {/* This forces the state to be active in the route paths */}
                  <Outlet />
                </CuriiousStateContextProvider>
              }
            >
              <Route path=":instance/" element={<CuriiousStateGate />}>
                <Route path="world/:worldId" element={<WorldLoader />} />
              </Route>
            </Route>
            <Route
              path="platform/capturedetails"
              element={<CaptureDetails />}
            />
          </Routes>
        </div>
      </HelmetProvider>
    );
  }
}

export default App;
