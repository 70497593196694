import { Instance } from "../components/models/Instance";
import axiosInstance from "./apiService";

const InstanceService = {
  getInstancesByDisplayName(
    api: string,
    displayName: string
  ): Promise<Instance> {
    return axiosInstance().get(
      `${api}/v1/Instances/DisplayName/${displayName}`
    );
  },
};

export default InstanceService;
