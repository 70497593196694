import React, { CSSProperties, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ReactComponent as XCircleFill } from "./../images/XCircleFill.svg";

export default function GuestCallToAction() {
  const [hover, setHover] = useState<boolean>(false);
  const [dismiss, setDismiss] = useState<boolean>(false);
  const navigate = useNavigate();

  function returnToLoginPage(): void {
    //To return to the login page with the showlogin parameter set.
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.append("showlogin", "true");
    navigate(
      { pathname: window.location.pathname, search: searchParams.toString() },
      { replace: true }
    );
    window.location.reload();
  }

  const toolbarStyle: CSSProperties = {
    position: "sticky",
    top: "0",
    backgroundColor: "#007EB5",
    padding: "0.3em",
    width: "100%",
    zIndex: "11",
    fontSize: "0.9em",
    fontWeight: "600",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const callToActionStyle: CSSProperties = {
    flexGrow: "1",
    textAlign: "center",
  };

  const buttonStyle: CSSProperties = {
    color: "white",
    background: hover ? "rgba(255,255,255,0.15)" : "none",
    border: "solid",
    borderColor: "white",
    borderWidth: "1px",
    borderRadius: "8px",
    fontWeight: "bold",
  };
  const closeButtonStyle: CSSProperties = {
    float: "right",
    color: "white",
    background: "none",
    border: "0",
    maxHeight: "90%",
  };

  if (dismiss) {
    return <Outlet />;
  }
  return (
    <div style={toolbarStyle}>
      <div style={callToActionStyle}>
        <span>
          Have an account?&nbsp;&nbsp;
          <button
            style={buttonStyle}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => returnToLoginPage()}
          >
            Log in
          </button>
          &nbsp;&nbsp;for full access.
        </span>
      </div>
      <button style={closeButtonStyle} onClick={() => setDismiss(true)}>
        <XCircleFill />
      </button>
    </div>
  );
}
