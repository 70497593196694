import React, { useContext, useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";

// Don't forget the CSS: core and the UI components + plugins you are using.
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";
import XHRUpload from "@uppy/xhr-upload";
import {
  ApiContext,
  CuriiousUserContext,
} from "./CuriiousStateContextProvider";
import { Outlet } from "react-router-dom";
import ImageEditor from "@uppy/image-editor";
import { UnityContextHook } from "react-unity-webgl/distribution/types/unity-context-hook";

type Props = {
  uploaderType: "Avatar" | "Bulletin" | null;
  unityContextHook: UnityContextHook;
  maxUploads: number;
  closeFunction: Function;
};

export default function UppyImageUploader({
  uploaderType,
  unityContextHook,
  closeFunction,
  maxUploads,
}: Props) {
  const apiContext = useContext(ApiContext);
  const userContext = useContext(CuriiousUserContext);
  const [accessToken, setAccessToken] = useState<string>();
  const [uppy, setUppy] = useState<Uppy>();

  useEffect(() => {
    if (accessToken) {
      const newUppy = new Uppy();

      if (uploaderType === "Avatar") {
        newUppy
          .use(XHRUpload, {
            endpoint: `${apiContext?.url}/v1/Users/Avatar/Upload`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .use(ImageEditor, {
            cropperOptions: {
              croppedCanvasOptions: {},
              aspectRatio: 1.0,
            },
          })
          .on("upload-success", (file, response) => {
            console.log("Letting unity know a new avatar has been uploaded");
            unityContextHook.sendMessage(
              "ProfileUI",
              "UpdateProfileImage",
              JSON.stringify(response.body)
            );
            closeFunction();
          })
          .on("upload-error", (file, error, response) => {
            console.log(response?.status);
          })
          .setOptions({
            restrictions: {
              maxNumberOfFiles: maxUploads,
            },
          });
      } else if (uploaderType === "Bulletin") {
        newUppy
          .use(XHRUpload, {
            endpoint: `${apiContext?.url}/v1/Bulletins/Image/Upload`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .use(ImageEditor, {})
          .on("upload-success", (file, response) => {
            console.log(
              "Letting unity know new bulletin images have been added"
            );
            unityContextHook.sendMessage(
              "UploadStoryContainer",
              "NewStoryImageRecieved",
              JSON.stringify(response.body)
            );
            // CloseFunction();
          })
          .on("complete", () => {
            console.log("All uploads complete");
            closeFunction();
            // console.log(result);
          })
          .on("upload-error", (file, error, response) => {
            // console.log(response?.status);
          })
          .setOptions({
            restrictions: {
              maxNumberOfFiles: maxUploads,
            },
          });
      } else {
        console.log("Unknown uploader type");
      }

      //Set the uppy
      setUppy(newUppy);
    }

    async function GrabAccessToken() {
      setAccessToken(await userContext.getApiToken());
    }

    GrabAccessToken();

    // eslint-disable-next-line
  }, [uploaderType, accessToken, maxUploads]);

  if (uppy) {
    return (
      <Dashboard
        uppy={uppy}
        plugins={["ImageEditor"]}
        autoOpenFileEditor={uploaderType === "Avatar"}
      />
    );
  } else {
    return <Outlet />;
  }
}
