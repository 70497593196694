import React, { useEffect, useState } from "react";
import getVideoId from "get-video-id";
import { ContainerRect } from "yet-another-react-lightbox";

type Props = {
  videoUrl: string;
  rect: ContainerRect;
};

export default function Youtube({ videoUrl, rect }: Props) {
  const [embedLink, setEmbedLink] = useState<string>();

  useEffect(() => {
    const { id } = getVideoId(videoUrl);
    setEmbedLink(`https://www.youtube.com/embed/${id}?&autoplay=1`);
  }, [videoUrl]);

  return (
    <iframe
      // Frame should default to 16/9 aspect
      width={Math.min(
        rect.width,
        rect.width,
        (rect.width * rect.height) / rect.height
      )}
      height={(rect.width / 16) * 9}
      src={embedLink}
      title={"Pleasant Company Hub Youtube Embed"}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}
