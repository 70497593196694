import React, { CSSProperties, useEffect, useState } from "react";
import { DowntimeHandler } from "./models/DowntimeHandler";

type Props = {
  downtimeHandler: DowntimeHandler;
};

export default function SiteDown({ downtimeHandler }: Props) {
  const [downHeadline, setDownHeadline] = useState<string>(
    "Curiious is experiencing technical difficulties"
  );
  const [counter, setCounter] = useState<number>(100);

  useEffect(() => {
    if (downtimeHandler) {
      if (downtimeHandler.downHeadline) {
        setDownHeadline(downtimeHandler.downHeadline);
      }
      setCounter(downtimeHandler.redirectDelaySeconds as number);
    }
  }, [downtimeHandler]);

  useEffect(() => {
    //This is how we handle the timer.
    if (downtimeHandler.redirect) {
      if (counter && counter > 0) {
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        //Redirect to the given page
        console.log("Redirecting...");
        window.location.assign(downtimeHandler.redirectLink as string);
      }
    }
  }, [counter, downtimeHandler]);

  const downContainer: CSSProperties = {
    // position: 'relative',
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  };

  const headerStyle: CSSProperties = {
    fontWeight: "300",
    fontSize: "28pt",
  };
  const timerStyle: CSSProperties = {
    fontWeight: "500",
    fontSize: "12pt",
  };

  return (
    <div style={downContainer}>
      <p style={headerStyle}>{downHeadline}</p>
      <p>{downtimeHandler.downDescription}</p>
      {downtimeHandler.redirect && (
        <p style={timerStyle}>Redirecting in {counter}</p>
      )}
    </div>
  );
}
