import React, { Fragment, useCallback, useContext, useEffect } from "react";
import {
  ApiContext,
  CuriiousUserContext,
  InstanceContext,
  ViewStateContext,
  WorldContext,
} from "./CuriiousStateContextProvider";
import { useSearchParams } from "react-router-dom";
import { Convert, ViewState } from "./models/ViewState";
import { v4 as uuidv4 } from "uuid";
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import { UnityContextHook } from "react-unity-webgl/distribution/types/unity-context-hook";

type Props = {
  unityContextHook: UnityContextHook;
};

type UnitySignal = {
  uuid: string; //the guid
  payload: any; //the payload object - could be anything.
};

export default function StateWrangler({ unityContextHook }: Props) {
  const apiContext = useContext(ApiContext);
  const instanceContext = useContext(InstanceContext);
  const curiiousUserContext = useContext(CuriiousUserContext);
  const worldContext = useContext(WorldContext);
  const viewContext = useContext(ViewStateContext);
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams();

  //Register listeners
  useEffect(
    () => {
      // construct the event listener pair array
      const eventListenerPairs = [
        {
          event: "GetInstanceState",
          handler: handleGetInstanceState,
        },
        {
          event: "GetUserState",
          handler: handleGetUserState,
        },
        {
          event: "GetAccessToken",
          handler: handleGetAccessToken,
        },
        {
          event: "GetApiState",
          handler: handleGetApiState,
        },
        {
          event: "GetViewState",
          handler: handleGetViewState,
        },
        {
          event: "SetViewState",
          handler: handleSetViewState,
        },
        {
          event: "GetWorldState",
          handler: handleGetWorldState,
        },
        {
          event: "SetWorldState",
          handler: handleSetWorldState,
        },
      ];

      // register the event listeners on side effect
      eventListenerPairs.forEach((pair) => {
        unityContextHook.addEventListener(pair.event, pair.handler);
      });

      // cleanup the event listeners when the component unmounts to prevent memory leaks
      return () => {
        eventListenerPairs.forEach((pair) => {
          unityContextHook.removeEventListener(pair.event, pair.handler);
        });
      };
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const SignalBox = useCallback(
    (guid: string, payload: any) => {
      //Send this signal to the equivalent signal box running in unity.
      const unitySignal: UnitySignal = {
        uuid: guid,
        payload: payload,
      };

      console.log(`Signalbox is sending signal payload ${unitySignal.uuid}`);
      unityContextHook.sendMessage(
        "UIManager",
        "SignalBox",
        JSON.stringify(unitySignal)
      );
    },
    [unityContextHook]
  );

  //Detect forward and backward button presses
  useEffect(() => {
    const handlePopstate = () => {
      console.log("forward or back pressed");

      //
      const viewParams = searchParams.get("view");
      if (viewParams) {
        console.log("There is a view param update");
        //Store it as our new state, and then let unity know.
        console.log(decodeURIComponent(viewParams));
        const vs = Convert.toViewState(decodeURIComponent(viewParams));

        //Let unity know
        NotifyViewStateUpdate(vs);
        //And store it
        viewContext?.setViewState(vs);
      }

      // const urlParams = new URLSearchParams(window.location.search);
      // const stttaaate = urlParams.get('view');
      // if (stttaaate)
      // {
      //     //Let the notify handlers know that we've pressed forward or back.

      //     // console.log(decodeURIComponent(stttaaate));
      //     NotifyPlayerStateUpdate(decodeURIComponent(stttaaate));
      //     if (worldContext?.worldState)
      //     {
      //         NotifyWorldStateUpdate(worldContext?.worldState);
      //     }

      // }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
    // eslint-disable-next-line
  }, []);

  //Instance State
  const handleGetInstanceState = useCallback(() => {
    console.log("Unity is requestig an instance");
    return JSON.stringify(instanceContext.instanceState);
    // eslint-disable-next-line
  }, [unityContextHook]);

  //User State
  const handleGetUserState = useCallback(() => {
    console.log("Unity is requesting the user");
    const userStateString = JSON.stringify(
      curiiousUserContext.curiiousUserState
    );
    console.log(userStateString);
    return userStateString;
    // eslint-disable-next-line
  }, [unityContextHook]);

  //Access token - not strictly a state, but in the same realm
  const handleGetAccessToken = useCallback(() => {
    // Grab the access token and pass it back to unity.
    // This is slightly more complicated, because unity can't await stuff
    // without going through our wrapper.
    console.log("Unity has requested the access token");

    //Make a uuid to send to unity
    const uuid = uuidv4();

    // Return the uuid via the signalbox
    // Unity.send("UIManager", "Poo");
    // curiiousUserContext.getApiToken().then((value) =>
    // {
    //     SignalBox(uuid, value)
    // });

    poopoo(uuid);

    // Return the ref to unity so it can listen to it.
    return uuid;
    // eslint-disable-next-line
  }, [unityContextHook]);

  const poopoo = useCallback(
    async (guid: string) => {
      const token = await curiiousUserContext.getApiToken();
      SignalBox(guid, token);
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  //Api State
  const handleGetApiState = useCallback(() => {
    console.log("Unity is requesting the API");
    console.log("Sending: " + apiContext!.url);
    return apiContext!.url as string;
    // eslint-disable-next-line
  }, [unityContextHook]);

  //Player State
  const handleGetViewState = useCallback(() => {
    console.log("Unity is requesting the player state");
    if (viewContext?.viewState) {
      //Viewstate is set, send it to unity.
      return Convert.viewStateToJson(viewContext.viewState);
    } else {
      //Nothing there, so return an empty string.
      return "";
    }
    // eslint-disable-next-line
  }, [unityContextHook]);

  const handleSetViewState = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      console.log("Unity is requesting we store the view state.");
      const viewStateJson = unityParam as string;
      // console.log(viewStateJson);

      if (viewContext && viewStateJson) {
        //A for real object.
        viewContext.setViewState(Convert.toViewState(viewStateJson));
      } else {
        //Not an empty string
        viewContext?.setViewState(undefined);
      }
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const NotifyViewStateUpdate = useCallback(
    (viewState: ViewState) => {
      console.log("Notifying Player State update");
      unityContextHook.sendMessage(
        "Player",
        "NotifyPlayerStateChange",
        JSON.stringify(viewState)
      );
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  //World State
  const handleGetWorldState = useCallback(() => {
    //Eventually we'll return the whole world object as a string.
    console.log("Unity is requesting the world state");
    if (worldContext?.worldState) {
      console.log(`Returning world state: ${worldContext.worldState}`);
      return worldContext.worldState;
    } else {
      //Return 0 for no world specified.
      return 0;
    }
    // eslint-disable-next-line
  }, [unityContextHook]);

  const handleSetWorldState = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      const worldStateNumber = unityParam as number;

      console.log(
        `Unity is requesting we store the world state: ${worldStateNumber}`
      );
      worldContext?.setWorldState(worldStateNumber);
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  // function NotifyWorldStateUpdate(worldStateState: number)
  // {
  //     console.log('Notifying World State update');
  //     // Unity.send("Player", "NotifyPlayerStateChange", worldStateState);
  // }

  return <Fragment></Fragment>;
}
