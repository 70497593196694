import axiosInstance from "./apiService";
import curiiousConfig from "../curiiousConfig.json";
import { CuriiousUser } from "../components/models/CuriiousUser";

const UserService = {
  getUserByUUID(
    token: string,
    api: string = curiiousConfig.apiUrl.production,
    uuid: string
  ): Promise<CuriiousUser> {
    return axiosInstance(token).get(
      `${api}/v1/Users/UUID/${encodeURIComponent(uuid)}`
    );
  },
  postUnregisteredUserByUUID(
    api: string = curiiousConfig.apiUrl.production,
    instanceId: number,
    uuid: string
  ): Promise<CuriiousUser> {
    return axiosInstance().post(
      `${api}/v1/Users/Instance/${instanceId.toString()}/Unregistered/${uuid}`
    );
  },
};

export default UserService;
