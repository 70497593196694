import ReactGA from "react-ga4";
const { DateTime } = require("luxon");

export function PrintString(message: string): void {
  console.log(message);
}

export function ConvertDatetimeToUTC(
  yr: number,
  mnth: number,
  dy: number,
  hr: number,
  min: number,
  tz: string
): string {
  var d = DateTime.fromObject(
    { year: yr, month: mnth, day: dy, hour: hr, minute: min },
    { zone: tz }
  );
  d = d.toUTC();

  var returnString =
    String(d.month).padStart(2, "0") +
    "/" +
    String(d.day).padStart(2, "0") +
    "/" +
    d.year +
    " " +
    String(d.hour).padStart(2, "0") +
    ":" +
    String(d.minute).padStart(2, "0") +
    ":" +
    String(d.second).padStart(2, "0");
  // console.log('Returned datetime: ' + returnString);

  return returnString;
}

export function OpenNewTab(url: string): void {
  //This... uhh... opens a new tab.
  window.open(url, "_blank");
}

export function SendGAEvent(eventname: string, eventdata: string): void {
  // We've had issues with GA sending null data, so catch that just in case.
  if (eventname && eventdata) {
    // console.log("I should be sending a GA event!!");
    const obj = JSON.parse(eventdata);

    //Because unity is (weirdly) awful at converting dates, we have to do this in JS.
    //We'll append it to the object before sending it.
    var sydneyDate = DateTime.now().setZone("Australia/Sydney");

    obj.datetime_c = sydneyDate.toFormat("yyyy-MM-dd HH:mm:ss");
    obj.hour_c = sydneyDate.hour;

    ReactGA.event(eventname, obj);
  }
}
