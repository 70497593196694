// To parse this data:
//
//   import { Convert, Instance } from "./file";
//
//   const instance = Convert.toInstance(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface Instance {
  id: number;
  eventId?: string;
  eventPlatform?: string;
  allowRegisteredUsers: boolean;
  allowUnregisteredUsers: boolean;
  owner?: string | null;
  ssoConnectionName?: string | null;
  name?: string;
  description?: string | null;
  displayName?: string | null;
  logoUrl?: string | null;
  primaryColor?: string;
  secondaryColor?: string;
  customLoadingPage?: CustomLoadingPage | null;
  startWorld?: number;
  multiUserInteraction?: boolean;
}

export interface CustomLoadingPage {
  baseColor?: string;
  highlightColor?: string;
  styleSheet?: StyleSheet | null;
  customLoadingBackground?: string;
}

export interface StyleSheet {
  id?: number;
  styles?: Style[] | null;
}

export interface Style {
  id?: string;
  name?: string;
  openingTags?: string;
  closingTags?: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toInstance(json: string): Instance {
    return cast(JSON.parse(json), r("Instance"));
  }

  public static instanceToJson(value: Instance): string {
    return JSON.stringify(uncast(value, r("Instance")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any = ""): never {
  if (key) {
    throw Error(
      `Invalid value for key "${key}". Expected type ${JSON.stringify(
        typ
      )} but got ${JSON.stringify(val)}`
    );
  }
  throw Error(
    `Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`
  );
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ""): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue("array", val);
    return val.map((el) => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue("Date", val);
    }
    return d;
  }

  function transformObject(
    props: { [k: string]: any },
    additional: any,
    val: any
  ): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue("object", val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach((key) => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key)
        ? val[key]
        : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, prop.key);
    });
    Object.getOwnPropertyNames(val).forEach((key) => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === "object" && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers")
      ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")
      ? transformArray(typ.arrayItems, val)
      : typ.hasOwnProperty("props")
      ? transformObject(getProps(typ), typ.additional, val)
      : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

// eslint-disable-next-line
function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  Instance: o(
    [
      { json: "id", js: "id", typ: 0 },
      { json: "eventId", js: "eventId", typ: u(undefined, "") },
      { json: "eventPlatform", js: "eventPlatform", typ: u(undefined, "") },
      {
        json: "allowRegisteredUsers",
        js: "allowRegisteredUsers",
        typ: u(undefined, true),
      },
      {
        json: "allowUnregisteredUsers",
        js: "allowUnregisteredUsers",
        typ: u(undefined, true),
      },
      { json: "owner", js: "owner", typ: u(undefined, u(null, "")) },
      {
        json: "ssoConnectionName",
        js: "ssoConnectionName",
        typ: u(undefined, u(null, "")),
      },
      { json: "name", js: "name", typ: u(undefined, "") },
      {
        json: "description",
        js: "description",
        typ: u(undefined, u(null, "")),
      },
      {
        json: "displayName",
        js: "displayName",
        typ: u(undefined, u(null, "")),
      },
      { json: "logoUrl", js: "logoUrl", typ: u(undefined, u(null, "")) },
      { json: "primaryColor", js: "primaryColor", typ: u(undefined, "") },
      { json: "secondaryColor", js: "secondaryColor", typ: u(undefined, "") },
      {
        json: "customLoadingPage",
        js: "customLoadingPage",
        typ: u(undefined, u(r("CustomLoadingPage"), null)),
      },
      { json: "startWorld", js: "startWorld", typ: u(undefined, 0) },
      {
        json: "multiUserInteraction",
        js: "multiUserInteraction",
        typ: u(undefined, true),
      },
    ],
    false
  ),
  CustomLoadingPage: o(
    [
      { json: "baseColor", js: "baseColor", typ: u(undefined, "") },
      { json: "highlightColor", js: "highlightColor", typ: u(undefined, "") },
      {
        json: "styleSheet",
        js: "styleSheet",
        typ: u(undefined, u(r("StyleSheet"), null)),
      },
      {
        json: "customLoadingBackground",
        js: "customLoadingBackground",
        typ: u(undefined, ""),
      },
    ],
    false
  ),
  StyleSheet: o(
    [
      { json: "id", js: "id", typ: u(undefined, 0) },
      {
        json: "styles",
        js: "styles",
        typ: u(undefined, u(a(r("Style")), null)),
      },
    ],
    false
  ),
  Style: o(
    [
      { json: "id", js: "id", typ: u(undefined, "") },
      { json: "name", js: "name", typ: u(undefined, "") },
      { json: "openingTags", js: "openingTags", typ: u(undefined, "") },
      { json: "closingTags", js: "closingTags", typ: u(undefined, "") },
    ],
    false
  ),
};
