import React, { CSSProperties } from "react";

type Props = {
  errorType: "warning" | "error";
  errorText: string;
  centreOnPage: boolean;
};

export default function ErrorMessage({
  errorType,
  errorText,
  centreOnPage,
}: Props) {
  const errorStyle: CSSProperties = {
    borderRadius: "10px",
    padding: "15px",
    textAlign: "center",
    width: "85%",
    maxWidth: "600px",
    fontWeight: "500",
    backgroundColor: "#FF295019",
    whiteSpace: "pre-wrap",
    color: "#FF3B52",
    border: "1px solid",
  };

  const errorContainer: CSSProperties = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  if (centreOnPage) {
    return (
      <div style={errorContainer}>
        <div style={errorStyle}>{errorText}</div>
      </div>
    );
  }

  return <div style={errorStyle}>{errorText}</div>;
}
