import axiosInstance from "./apiService";
import { Build } from "../components/models/Build";

const BuildService = {
  getBuildById(token: string, api: string, buildId: number): Promise<Build> {
    return axiosInstance(token).get(`${api}/v1/Builds/${buildId.toString()}`);
  },
  getBuildLatest(token: string, api: string): Promise<Build> {
    return axiosInstance(token).get(`${api}/v1/Builds/Latest`);
  },
};

export default BuildService;
