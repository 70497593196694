import React, { Fragment, useContext, useEffect, useState } from "react";
import curiiousConfig from "../curiiousConfig.json";
import { Build } from "./models/Build";
import {
  BuildContext,
  CuriiousUserContext,
} from "./CuriiousStateContextProvider";
import BuildService from "../services/buildService";
import LoadingSpinner from "./LoadingSpinner";
import CuriiousEventPlatformUnity from "./CuriiousEventPlatformUnity";
import { UnityConfig } from "react-unity-webgl";
import { useNavigate } from "react-router-dom";

const localUnityBuild: UnityConfig = {
  productName: "Curiious Event Platform",
  companyName: "Curiious",
  // The url's of the Unity WebGL runtime, these paths are public and should be
  // accessible from the internet and relative to the index.html.
  dataUrl: "unitybuild/curiiouseventsplatform.data",
  frameworkUrl: "unitybuild/curiiouseventsplatform.framework.js",
  loaderUrl: "unitybuild/curiiouseventsplatform.loader.js",
  codeUrl: "unitybuild/curiiouseventsplatform.wasm",
  streamingAssetsUrl: "unitybuild/StreamingAssets",
  // Additional configuration options.
  webglContextAttributes: {
    preserveDrawingBuffer: true,
  },
};

export default function CuriiousBuildSelector() {
  //This component is where we set which builds we're going to use.

  const [unityBuild, setUnityBuild] = useState<UnityConfig>();
  const userContext = useContext(CuriiousUserContext);
  const navigate = useNavigate();

  //So we dont maintain two build databases, we should ALWAYS look to the production API for info about the build.
  //even if we've specified to use otherwise elsewhere on the platform/frontend..
  const productionApiPath = curiiousConfig.apiUrl.production;

  //Also, look for a buildid parameter so we can specify the build if we want to.
  //This is made available via context.
  const buildId = useContext(BuildContext);

  //Change this flag to true to use the build referenced above. <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const useLocalBuild: boolean = false;

  useEffect(() => {
    //We should remove the showloading parameter if it's there, so if someone shares the links, it won't be added.
    //maybe this is bad UX?
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("showlogin")) {
      searchParams.delete("showlogin");
    }
    navigate(
      { pathname: window.location.pathname, search: searchParams.toString() },
      { replace: true }
    );
  }, [navigate]);

  useEffect(() => {
    //Assess the buildId.
    console.log("Querying Build Id");

    //Convenience function to convert our build to a unity context build.
    function BuildToUnityConfig(build: Build): UnityConfig {
      const dynamicUnityContext: UnityConfig = {
        productName: "Curiious Event Platform",
        companyName: "Curiious",
        // The url's of the Unity WebGL runtime, these paths are public and should be
        // accessible from the internet and relative to the index.html.
        dataUrl: build.data,
        frameworkUrl: build.framework,
        loaderUrl: build.loader,
        codeUrl: build.code,
        streamingAssetsUrl: build.streamingAssets,
        // Additional configuration options.
        webglContextAttributes: {
          preserveDrawingBuffer: true,
        },
      };
      return dynamicUnityContext;
    }

    async function GetLatestBuild() {
      let buildResponse = await BuildService.getBuildLatest(
        await userContext.getApiToken(),
        productionApiPath
      );
      console.log(`Getting latest build (id:${buildResponse.id})`);
      setUnityBuild(BuildToUnityConfig(buildResponse));
    }
    async function GetBuildById(buildNumber: number) {
      console.log(`Getting build id: ${buildNumber}`);
      let buildResponse = await BuildService.getBuildById(
        await userContext.getApiToken(),
        productionApiPath,
        buildNumber
      );
      setUnityBuild(BuildToUnityConfig(buildResponse));
    }

    //Get the builds we need.
    if (useLocalBuild) {
      //We override things to use a locally specified build
      setUnityBuild(localUnityBuild);
    } else {
      //Otherwise hit the api
      if (buildId) {
        //BuildId has a set (non zero) value
        GetBuildById(buildId);
      } else {
        //No build defined, just get the latest one.
        GetLatestBuild();
      }
    }

    // eslint-disable-next-line
  }, [buildId, userContext]);

  return (
    <Fragment>
      {unityBuild ? (
        <CuriiousEventPlatformUnity unityConfig={unityBuild} />
      ) : (
        <LoadingSpinner heightVh={20} />
      )}
    </Fragment>
  );
}
