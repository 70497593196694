import {
  LogoutOptions,
  RedirectLoginOptions,
  useAuth0,
} from "@auth0/auth0-react";
import React, {
  CSSProperties,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import LoadingSpinner from "./LoadingSpinner";
import useSWR from "swr";
import UserService from "../services/userService";
import "./styles/LoginForm.css";
import {
  ApiContext,
  CuriiousUserContext,
  InstanceContext,
} from "./CuriiousStateContextProvider";
import { Instance } from "./models/Instance";
import { Convert } from "./models/CuriiousUser";
import ErrorMessage from "./ErrorMessage";
import CuriiousBuildSelector from "./CuriiousBuildSelector";
import { v4 as uuidv4 } from "uuid";

export default function CuriiousAuthGate() {
  const { isAuthenticated, isLoading, error } = useAuth0();
  const instanceContext = useContext(InstanceContext);
  const [showLoginButton, setShowLoginButton] = useState<boolean>(false);
  const [gateLoadingState, setGateLoadingState] = useState<
    "None" | "Registered" | "Unregistered"
  >("None");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("showlogin")) {
      //When we are redirected back from a situation where guest and registered
      //users are allowed, we can force the login buttons to show.
      console.log("showing login");
      setShowLoginButton(true);
    }
  }, []);

  if (isLoading) {
    //Show the loading spinner
    return <LoadingSpinner heightVh={20} />;
  }
  if (error) {
    let errorText: string = `There are problems with your user credentials.\nPlease log out and try again.`;
    return (
      <Fragment>
        <ErrorMessage
          errorType={"error"}
          errorText={errorText}
          centreOnPage={false}
        />
        <LogoutButton />
      </Fragment>
    );
  }

  //Things are handled slightly differently based on what types of login the instance supports.
  if (instanceContext.instanceState) {
    if (
      instanceContext.instanceState.allowRegisteredUsers &&
      instanceContext.instanceState.allowUnregisteredUsers
    ) {
      //Registered and unregistered allowed.
      if (isAuthenticated) {
        return <CuriiousRegisteredUserGate />;
      } else {
        if (showLoginButton) {
          return (
            <Fragment>
              {
                //Dont show the login button if unregistered is loading
                gateLoadingState !== "Unregistered" && (
                  <LoginButton
                    instance={instanceContext.instanceState!}
                    setGateLoadingState={setGateLoadingState}
                  />
                )
              }
              {
                //Dont show the guest button if registered is loading
                gateLoadingState !== "Registered" && (
                  <LoginAsGuestButton
                    setGateLoadingState={setGateLoadingState}
                  />
                )
              }
            </Fragment>
          );
        }
        return <CuriiousUnregisteredUserGate />;
      }
    } else if (
      instanceContext.instanceState.allowRegisteredUsers &&
      !instanceContext.instanceState.allowUnregisteredUsers
    ) {
      // Registered only
      if (isAuthenticated) {
        return <CuriiousRegisteredUserGate />;
      } else {
        return (
          <LoginButton
            instance={instanceContext.instanceState!}
            setGateLoadingState={setGateLoadingState}
          />
        );
      }
    } else if (
      !instanceContext.instanceState.allowRegisteredUsers &&
      instanceContext.instanceState.allowUnregisteredUsers
    ) {
      // unregistered only
      return <CuriiousUnregisteredUserGate />;
    } else {
      //Instance is closed. No users are allowed in.
      const errorText = `${instanceContext.instanceState.name} is not currently accepting visitors.`;
      return (
        <ErrorMessage
          errorType={"warning"}
          errorText={errorText}
          centreOnPage={false}
        />
      );
    }
  } else {
    //We have no instance, so spin.
    return <LoadingSpinner heightVh={20} />;
  }
}

type LoginProps = {
  instance: Instance;
  setGateLoadingState: React.Dispatch<
    React.SetStateAction<"None" | "Registered" | "Unregistered">
  >;
};
//Login button for Auth0.
function LoginButton({ instance, setGateLoadingState }: LoginProps) {
  const { loginWithRedirect } = useAuth0();
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const isPeugeot: boolean = instance.id === 42 || instance.id === 36;

  //Login button looks different depending on whether we are peugeot or not
  const loginButtonDynamicStyle: CSSProperties = {
    fontFamily: isPeugeot ? "Peugeot" : "",
    backgroundColor: isPeugeot
      ? instance.customLoadingPage?.highlightColor
      : "",
  };

  function DoLogin() {
    //On login we pass any parameters we want to be remembered
    setGateLoadingState("Registered");

    const loginOptions: RedirectLoginOptions = {
      appState: {
        returnTo: window.location.pathname + window.location.search,
      },
      authorizationParams: {
        connection: instance.ssoConnectionName
          ? instance.ssoConnectionName
          : undefined,
      },
    };

    loginWithRedirect(loginOptions);
  }

  if (loginLoading) {
    return <LoadingSpinner heightVh={20} />;
  }
  return (
    <button
      className="LoginButton PrimaryButton NativeLoginButton"
      onClick={() => {
        setLoginLoading(true);
        DoLogin();
      }}
      style={loginButtonDynamicStyle}
    >
      LOG IN
    </button>
  );
}

function LogoutButton() {
  const { logout } = useAuth0();

  const logoutOptions: LogoutOptions = {
    logoutParams: {
      returnTo: window.location.origin,
    },
  };

  return (
    <button
      className="LoginButton PrimaryButton NativeLoginButton"
      onClick={() => logout(logoutOptions)}
    >
      LOG OUT
    </button>
  );
}

type LoginGuestProps = {
  setGateLoadingState: React.Dispatch<
    React.SetStateAction<"None" | "Registered" | "Unregistered">
  >;
};
function LoginAsGuestButton({ setGateLoadingState }: LoginGuestProps) {
  const [allowGuest, setAllowGuest] = useState<boolean>(false);

  function triggerGuestLogin(): void {
    console.log("Triggering Guest Login");
    if (!allowGuest) {
      setAllowGuest(true);
      setGateLoadingState("Unregistered");
    }
  }

  const guestSpan: CSSProperties = {
    marginTop: "1em",
    marginBottom: "1em",
    fontSize: "13pt",
  };

  if (allowGuest) {
    //Load it in.
    return <CuriiousUnregisteredUserGate />;
  } else {
    //We need to flag it.
    return (
      <Fragment>
        <span style={guestSpan}>Or continue as a guest</span>
        <button
          className="LoginButton NativeLoginButton GuestButton"
          onClick={() => triggerGuestLogin()}
        >
          GUEST
        </button>
      </Fragment>
    );
  }
}

function CuriiousRegisteredUserGate() {
  const { user, getAccessTokenSilently } = useAuth0();
  const apiContext = useContext(ApiContext);
  const curiiousUserContext = useContext(CuriiousUserContext);
  const instanceContext = useContext(InstanceContext);

  // Try and pull data about curiious user
  const {
    data: curUserData,
    isLoading: curUserLoading,
    error: curUserError,
  } = useSWR("User", async (token) => {
    //Since this is the first time we're grabbing this user, we should use
    //the auth0 function directly, rather than the generic context function
    //we've got. This is very hacky - I'm sorry.
    return UserService.getUserByUUID(
      await getAccessTokenSilently(),
      apiContext?.url,
      user?.sub!
    );
  });

  // State tracking effects.
  useEffect(() => {
    //If we get the curiious user response, store it in our state tracker
    if (curUserData) {
      const cu = Convert.toCuriiousUser(JSON.stringify(curUserData));
      curiiousUserContext.setCuriiousUserState(cu);
      console.log(
        `Welcome ${cu.firstName} ${cu.lastName} to the Curiious Platform`
      );
    }
    // eslint-disable-next-line
  }, [curUserData]);

  //Track the return states
  if (curUserError) {
    console.log("User credential errors. Unable to reconcile UUID.");
    let errorText: string = `There are problems with your user credentials.\nPlease log out and try again.\nIf issues persist, contact your administrator and provide the following reference:\n${
      user!.sub
    }`;
    return (
      <Fragment>
        <ErrorMessage
          errorType={"error"}
          errorText={errorText}
          centreOnPage={false}
        />
        <LogoutButton />
      </Fragment>
    );
  }
  if (curUserLoading) {
    // If we're loading
    return <LoadingSpinner heightVh={20} />;
  }
  if (curiiousUserContext.curiiousUserState?.id) {
    //If the curiious user context state has been set.

    //Make sure that the user actually has credentials to see this instance

    //If we have the curiios user, but they're not enrolled in this instance.
    if (
      curiiousUserContext.curiiousUserState.enrolledInstances!.includes(
        instanceContext.instanceState?.id!
      )
    ) {
      return <CuriiousBuildSelector />;
    } else {
      //Enrollment not valid. In the future we may offer an opportunity to signup.
      return (
        <Fragment>
          <ErrorMessage
            errorType={"error"}
            errorText={`You are not authorized to view this instance.\nPlease contact your event administrator.`}
            centreOnPage={false}
          />
          <LogoutButton />
        </Fragment>
      );
    }
  } else {
    return (
      <ErrorMessage
        errorType={"error"}
        errorText={"An unknown error occurred."}
        centreOnPage={false}
      />
    );
  }
}

function CuriiousUnregisteredUserGate() {
  //This is the loader for unregistered users. Basically the equivalent of the function above.

  const apiContext = useContext(ApiContext);
  const curiiousUserContext = useContext(CuriiousUserContext);
  const instanceContext = useContext(InstanceContext);

  useEffect(() => {
    async function GetGuestUser() {
      console.log("Generating unregistered/guest user...");
      //Guest users are semi persistant. We use the guid as a seed, so that names and shit are consistent.
      //we store a token on the users local storage to effectively "brand" them with a unique uuid.
      //obvs, if they clear their cache or whatevs, this will be reset.
      let machineUuid = localStorage.getItem("MachineUuid");

      if (!machineUuid) {
        machineUuid = uuidv4();
        localStorage.setItem("MachineUuid", machineUuid);
      }

      let guestResponse = await UserService.postUnregisteredUserByUUID(
        apiContext?.url,
        instanceContext.instanceState?.id!,
        machineUuid
      );
      console.log(
        `Welcome Guest ${guestResponse.firstName} ${guestResponse.lastName} to the Curiious Platform`
      );
      curiiousUserContext.setCuriiousUserState(guestResponse);
    }

    if (!curiiousUserContext.curiiousUserState) {
      GetGuestUser();
    }

    // eslint-disable-next-line
  }, [curiiousUserContext.curiiousUserState]);

  if (curiiousUserContext.curiiousUserState?.id) {
    return <CuriiousBuildSelector />;
  } else {
    // return (<div>Loading Guest.... gate</div>);
    return <LoadingSpinner heightVh={20} />;
  }
}
