import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { Fragment, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import curiiousConfig from "../../curiiousConfig.json";

type Props = {
  children: ReactElement<any, any>;
};

export default function Auth0ProviderWithHistory({ children }: Props) {
  const navigate = useNavigate();

  //Look at the url parameters, and swap auth0 tennants if necessary.
  const authTennant = new URLSearchParams(window.location.search).get("auth");
  var authDomain = curiiousConfig.auth0.production.domain;
  var authClientId = curiiousConfig.auth0.production.cliendId;
  var authRedirect = curiiousConfig.auth0.production.redirect;
  var authAudience = curiiousConfig.auth0.production.audience;
  if (authTennant === "dev") {
    console.log("Using development auth platform");
    authDomain = curiiousConfig.auth0.development.domain;
    authClientId = curiiousConfig.auth0.development.cliendId;
    authRedirect = curiiousConfig.auth0.development.redirect;
    authAudience = curiiousConfig.auth0.development.audience;
  }

  //Handle Auth0 Redirects
  const onRedirectCallback = async (appState: AppState | undefined) => {
    const returnTo: string =
      appState && appState?.returnTo
        ? appState?.returnTo
        : window.location.origin;

    console.log("Redirecting to: " + returnTo);
    navigate(returnTo, {
      replace: true,
    });
  };

  return (
    <Fragment>
      <Auth0Provider
        domain={authDomain}
        clientId={authClientId}
        authorizationParams={{
          redirect_uri: window.location.origin + "/" + authRedirect,
          audience: authAudience,
        }}
        onRedirectCallback={onRedirectCallback}
      >
        {children}
      </Auth0Provider>
    </Fragment>
  );
}
