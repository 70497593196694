import React, {
  CSSProperties,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet, useParams } from "react-router-dom";
import { ApiContext, InstanceContext } from "./CuriiousStateContextProvider";
import useSWR from "swr";
import InstanceService from "../services/instanceService";
import { Convert } from "./models/Instance";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import { LiveChatWidget } from "@livechat/widget-react";
import CuriiousDeviceGate from "./CuriiousDeviceGate";
import { Helmet } from "react-helmet-async";
import curiiousConfig from "../curiiousConfig.json";

export default function CuriiousInstance() {
  //Set the vars and hooks and states
  const params = useParams();
  const apiContext = useContext(ApiContext);
  const instanceContext = useContext(InstanceContext);
  const [pageResourcesLoaded, setPageResourcesLoaded] =
    useState<boolean>(false);
  const [isPeugeot, setIsPeugeot] = useState<boolean>(false); //I dont like hard coding like this. generalise when we have more use cases plz
  const instanceDisplayName: string = params.instance as string;

  //Try and pull data about the instance.
  const {
    data: instanceData,
    isLoading: instanceLoading,
    error: instanceError,
  } = useSWR("Instance", (token) => {
    if (apiContext?.url) {
      return InstanceService.getInstancesByDisplayName(
        apiContext.url,
        instanceDisplayName
      );
    }
  });

  // State tracking effects.
  useEffect(() => {
    //If we get valid instance data, store it.
    if (instanceData && !instanceContext.instanceState) {
      const instanceType = Convert.toInstance(JSON.stringify(instanceData));

      //Horrible hack, but if the ID is a Peugeot one, set styles appropriately.
      if (instanceType.id === 42 || instanceType.id === 36) {
        setIsPeugeot(true);
      }

      //Store it in our state tracker
      instanceContext.setInstanceState(instanceType);
    }
    // eslint-disable-next-line
  }, [instanceData]);

  function checkMin(theVis: string) {
    console.log("Checking help state " + theVis);
    if (theVis.includes("minimize")) {
      let help = document.getElementById("chat-widget-container");
      if (help) {
        console.log("Close Help");
        help.style.visibility = "hidden";
        instanceContext.setHelpVisibility("minimized");
      }
    }
  }
  // Styles
  const mainPageContainer: CSSProperties = {
    position: "relative",
    minHeight: "100vh",
    backgroundImage: `url("${
      instanceContext?.instanceState?.customLoadingPage?.customLoadingBackground
        ? instanceContext.instanceState.customLoadingPage
            .customLoadingBackground
        : ""
    }")`,
    WebkitBackgroundSize: "cover",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: instanceContext?.instanceState?.customLoadingPage?.baseColor
      ? instanceContext.instanceState.customLoadingPage.baseColor
      : "", //invert the text body colour if our bg is dark
    display: pageResourcesLoaded ? "" : "none", //dont show this div till it's all loaded
  };
  const instanceContentContainer: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: "8.5rem",
    minHeight: "93vh",
  };
  const eventLogo: CSSProperties = {
    maxWidth: "225px",
    maxHeight: "225px",
    marginTop: "10vh",
    paddingBottom: "2.3vh",
  };
  const instanceName: CSSProperties = {
    textTransform: "uppercase",
    paddingTop: "3.8vh",
    paddingBottom: "8vh",
    fontFamily: isPeugeot ? "Peugeot" : "",
    fontSize: isPeugeot ? "26pt" : "28pt",
    fontWeight: isPeugeot ? "500" : "600",
    maxWidth: "50%",
    textAlign: "center",
  };
  const instanceDescriptionText: CSSProperties = {
    paddingTop: "8vh",
    paddingBottom: "5vh",
    textAlign: "center",
    fontSize: "15pt",
    maxWidth: "55vw",
    fontFamily: isPeugeot ? "Peugeot" : "",
  };
  const poweredByContainer: CSSProperties = {
    fontSize: "8pt",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    bottom: "0",
    height: "7vh",
  };

  //Return states
  if (instanceLoading || !apiContext) {
    //We're looking at the api
    return <LoadingSpinner heightVh={100} />;
  }
  if (instanceError) {
    //There was an error for some reason.
    let errorString: string = `Something went wrong, try reloading this page`;
    if (instanceError.status) {
      if (Number(instanceError.status) === 404) {
        //we can't fine the instance.
        errorString = `Unable to find Instance "${instanceDisplayName}"\nThe cause could be a mistyped URL, broken link or deleted page.`;
      }
    }

    return (
      <ErrorMessage
        errorType="error"
        errorText={errorString}
        centreOnPage={true}
      />
    );
  }

  if (instanceContext.instanceState) {
    //Set up meta tags for formal return:
    const metaTitle: string = `${curiiousConfig.meta.applicationName} - ${
      instanceContext.instanceState
        ? instanceContext.instanceState.name
        : "Loading..."
    } `;
    const metaDescription: string = instanceContext.instanceState
      ? instanceContext.instanceState.description!
      : curiiousConfig.meta.applicationDescription;

    return (
      <Fragment>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />

          {/* <!-- Google / Search Engine Tags --> */}
          <meta itemProp="name" content={metaTitle} />
          <meta itemProp="description" content={metaDescription} />

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:url" content={"https://hub.curiious.com"} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
        </Helmet>
        <div
          style={mainPageContainer}
          onLoad={() => setPageResourcesLoaded(true)}
        >
          <div style={instanceContentContainer}>
            {instanceContext?.instanceState?.logoUrl && (
              <img
                alt={instanceContext?.instanceState?.name}
                src={instanceContext.instanceState.logoUrl}
                style={eventLogo}
              />
            )}
            <p style={instanceName}>{instanceContext?.instanceState?.name}</p>
            <div style={instanceDescriptionText}>
              {instanceContext?.instanceState?.description}
            </div>
            <CuriiousDeviceGate />
          </div>
          <LiveChatWidget
            license="18339702"
            visibility={instanceContext.helpVisibility}
            onVisibilityChanged={(vis) => checkMin(JSON.stringify(vis))}
          />
          <div style={poweredByContainer}>
            <div style={{ float: "left", marginLeft: "4.6vw" }}>
              Powered by{" "}
              <span style={{ fontWeight: "600" }}>Pleasant Company</span>
            </div>
            <div
              style={{ float: "right", marginRight: "4.6vw" }}
            >{`© Pleasant Company ${new Date().getFullYear()}`}</div>
          </div>
        </div>
        <Outlet />
      </Fragment>
    );
  } else {
    return <LoadingSpinner heightVh={100} />;
  }
}
