import React, { CSSProperties } from "react";
import { Spinner } from "reactstrap";

type Props = {
  heightVh: number;
};

export default function LoadingSpinner({ heightVh }: Props) {
  const spinnerStyle: CSSProperties = {
    textAlign: "center",
    minHeight: heightVh + "vh",
    // minWidth:'100wv',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div style={spinnerStyle}>
      <Spinner animation="border" variant="secondary" />
    </div>
  );
}
