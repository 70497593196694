import { useAuth0 } from "@auth0/auth0-react";
import React, { Fragment, useEffect, useState } from "react";
import ErrorMessage from "../ErrorMessage";
import LoadingSpinner from "../LoadingSpinner";

export default function Auth0Redirect() {
  //This is pretty empty, just provides us with a simple landing page from auth0 redirect.
  const { error } = useAuth0();
  const [errorMessage, setErrorMessage] = useState<string>(
    "There were errors logging in.\nPlease try again, and if problems persist, contact your administrator."
  );

  useEffect(() => {
    if (error) {
      setErrorMessage(`${error.name}: ${error.message}`);
    }
  }, [error]);

  if (error) {
    console.log("Error while logging in.");
    console.log("This likely occurred during a custom Auth0 action.");

    return (
      <Fragment>
        <ErrorMessage
          errorType={"error"}
          errorText={errorMessage}
          centreOnPage={true}
        />
        {/* <LogoutButton /> */}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <LoadingSpinner heightVh={100} />
    </Fragment>
  );
}
