import React from "react";
import {
  isChrome,
  isDesktop,
  isEdgeChromium,
  isFirefox,
} from "react-device-detect";
import ErrorMessage from "./ErrorMessage";
import CuriiousAuthGate from "./CuriiousAuthGate";

export default function CuriiousDeviceGate() {
  //Here is where we gatekeep based on the device. We only show the login auth switch
  //if the user is on a supported platform.

  if (isDesktop && (isChrome || isEdgeChromium || isFirefox)) {
    //Supported device
    return <CuriiousAuthGate />;
  } else {
    return (
      <ErrorMessage
        errorType="error"
        errorText={`Your current device or browser is unsupported.\nThe Curiious platform can be accessed from a Mac or PC computer, using a Chrome, or Edge browsers.`}
        centreOnPage={false}
      />
    );
  }
}
