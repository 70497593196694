import React, { CSSProperties, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CuriiousLogo from "./../images/CuriiousLogo.svg";
import PlatformLaptop from "./../images/PlatformLaptop.png";

export default function WelcomePage() {
  const navigate = useNavigate();

  // Define CSS for the page.
  const mainContainer: CSSProperties = {
    // backgroundColor: 'red',
    margin: "auto",
    marginTop: "7%",
    width: "90%",
  };

  const curlogo: CSSProperties = {
    maxWidth: "190px",
    paddingBottom: "9vh",
  };

  const footerSection: CSSProperties = {
    // backgroundColor: 'peru',
    width: "100%",
    textAlign: "right",
    fontSize: "12pt",
    marginTop: "5px",
  };

  const footerLogoButtons: CSSProperties = {
    textDecoration: "none",
    color: "black",
    marginLeft: "10px",
  };

  const d = new Date();
  let year = d.getFullYear();

  // To make ourselves backwards compatible with the old frontend, we want to check on
  // the *eventid* parameter, and redirect accordingly.
  const eventId = new URLSearchParams(window.location.search).get("eventid");
  //We used to hit the api for this, but with the security on the api now, we're just hard coding it
  //no one should be hitting these anymore anyway.

  useEffect(() => {
    if (eventId) {
      const eventNumber = Number(eventId);
      let redirectDisplayName = "";
      if (eventNumber === 6) {
        //Beyond EI.
        redirectDisplayName = "beyondei";
      } else if (eventNumber === 21) {
        //Beyond EI.
        redirectDisplayName = "mercercareeracademy";
      } else if (eventNumber === 22) {
        //Beyond EI.
        redirectDisplayName = "jnjinstituteanz";
      } else if (eventNumber === 37) {
        //Beyond EI.
        redirectDisplayName = "demo";
      } else if (eventNumber === 41) {
        //Beyond EI.
        redirectDisplayName = "eywelcome";
      }

      //Now redirect
      if (redirectDisplayName !== "") {
        navigate("/" + redirectDisplayName, { replace: true });
      }
    }
  }, [eventId, navigate]);

  return (
    <Fragment>
      <div style={mainContainer}>
        <div>
          <img alt="Curiious Logo" src={CuriiousLogo} style={curlogo} />
        </div>
        <div className="row align-items-start">
          <div className="col-lg-6">
            <h1 style={{ fontWeight: "300", fontSize: "28pt" }}>
              Welcome to the immersive event and learning platform built for
              impact.
            </h1>
            <p
              style={{
                marginTop: "40px",
                marginBottom: "40px",
                fontWeight: "300",
                fontSize: "12pt",
              }}
            >
              The Pleasant Company Hub is not only an alternative to
              face-to-face training and events, but a rich and ongoing immersive
              resource centre to be accessed anytime, from anywhere. The
              perpetual nature of the platform enables a variety of experiences
              leveraging existing materials and reporting usage trends to guide
              the refinement of content over time. We provide an innovative and
              captivating approach to learning exceeding anything a static
              online tool can deliver. Reach out to us, we’d love the
              opportunity to speak with your team on any areas of learning,
              meeting and engagement that you think would benefit from a fresh
              approach.
            </p>
            <div>
              <a
                href="https://curiious.com/get-in-touch"
                className="RoundedButton PrimaryButton"
              >
                Contact us
              </a>
            </div>
            <div style={{ marginTop: "25px", marginBottom: "45px" }}>
              <a
                href="https://pleasant.company"
                className="RoundedButton SecondaryButton"
              >
                pleasant.company
              </a>
            </div>
          </div>
          <div
            style={{ textAlign: "center", fontSize: "30pt" }}
            className="col-lg-6"
          >
            <img
              alt="Anytime. Anywhere."
              src={PlatformLaptop}
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <p>Anytime. Anywhere.</p>
          </div>
        </div>
        <div style={footerSection}>
          <div>
            <a
              href="https://www.youtube.com/channel/UCTA8U5bgmiWsVerwNOKf27g"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                style={footerLogoButtons}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="black"
                className="bi bi-youtube"
                viewBox="0 0 16 16"
              >
                <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
              </svg>
            </a>
            <a
              href="https://twitter.com/curiious_ii"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                style={footerLogoButtons}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="black"
                className="bi bi-twitter"
                viewBox="0 0 16 16"
              >
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/19013045"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                style={footerLogoButtons}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="black"
                className="bi bi-linkedin"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
              </svg>
            </a>
          </div>
          <p style={{ marginTop: "5px" }}>
            © Pleasant Company {year} all rights reserved
          </p>
        </div>
      </div>
    </Fragment>
  );
}
