import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Unity, UnityConfig, useUnityContext } from "react-unity-webgl";
import {
  ConvertDatetimeToUTC,
  OpenNewTab,
  PrintString,
  SendGAEvent,
} from "./BBJSIntegration";
import "./styles/App.css";
import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Hls from "hls.js";
import ErrorMessage from "./ErrorMessage";
import CuriiousLightbox from "./CuriiousLightbox";
import StateWrangler from "./StateWrangler";
import {
  CuriiousUserContext,
  InstanceContext,
} from "./CuriiousStateContextProvider";
import UppyImageUploader from "./UppyImageUploader";
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import GuestCallToAction from "./GuestCallToAction";
import { UserUtils } from "./models/CuriiousUser";

type Props = {
  unityConfig: UnityConfig;
};

declare global {
  interface Window {
    hls: Hls;
  }
}

export default function CuriiousEventPlatformUnity({ unityConfig }: Props) {
  const unityContextHook = useUnityContext(unityConfig);

  const { logout } = useAuth0();
  //Track the Unity app's state.
  const [isError, setIsError] = useState<boolean>(false);
  const [showGuestCTA, setShowGuestCTA] = useState<boolean>(false);
  const [errorMessageText, setErrorMessageText] = useState<string>("");
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [imageUploaderType, setImageUploaderType] = useState<
    "Avatar" | "Bulletin" | null
  >(null);
  const [focusStatus, setFocusStatus] = useState<boolean>(false);
  const [baseColor, setBaseColour] = useState<string | null>(null);
  const [highlightColor, setHighlightColor] = useState<string | null>(null);
  const [maxImageUploads, setMaxImageUploads] = useState<number>(1);
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );
  const instanceContext = useContext(InstanceContext);
  const curiiousUserContext = useContext(CuriiousUserContext);
  const [isUnityReady, setIsUnityReady] = useState<boolean>(false);
  const navigate = useNavigate();

  //Initial Setup
  useEffect(() => {
    //This is the initial setup when the instance is loaded
    window.hls = new Hls();
    if (window.hls) {
      console.log("HLS Successfully setup.");
    }
  }, []);

  //When unity is loaded, turn off the live chat helper.
  useEffect(() => {
    if (unityContextHook.isLoaded) {
      //TODO: unity is loaded, so turn off the live chat.
      //we can turn it back on again in unity via code.
      //
      if (instanceContext?.helpVisibility === "minimized") {
        let help = document.getElementById("chat-widget-container");
        if (help) {
          console.log("Close Help");
          help.style.visibility = "hidden";
          instanceContext?.setHelpVisibility("hidden");
        }
      }
    }
    // eslint-disable-next-line
  }, [unityContextHook.isLoaded]);

  useEffect(() => {
    //Set vars
    if (instanceContext?.instanceState?.customLoadingPage) {
      //Custom loading page stuff. This mainly effects the loading bar.
      if (instanceContext.instanceState.customLoadingPage.baseColor) {
        setBaseColour(
          instanceContext.instanceState.customLoadingPage.baseColor
        );
      }
      if (instanceContext.instanceState.customLoadingPage.highlightColor) {
        setHighlightColor(
          instanceContext.instanceState.customLoadingPage.highlightColor
        );
      }
    }

    //Are we a guest in an instance which also allows registered users?
    setShowGuestCTA(
      UserUtils.isGuest(curiiousUserContext.curiiousUserState!) &&
        instanceContext.instanceState!.allowRegisteredUsers &&
        instanceContext.instanceState!.allowUnregisteredUsers
    );
  }, [instanceContext.instanceState, curiiousUserContext.curiiousUserState]);

  //Track pixel ratio changes.
  useEffect(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  // When the component is mounted, we'll register some event listener.
  useEffect(
    () => {
      // construct the event listener pair array
      const eventListenerPairs = [
        {
          event: "SendString",
          handler: handleSendString,
        },
        {
          event: "ThrowError",
          handler: handleThrowError,
        },
        {
          event: "ConvertDatetimeToUTC",
          handler: handleConvertDatetimeToUTC,
        },
        {
          event: "OpenNewTab",
          handler: handleOpenNewTab,
        },
        {
          event: "NavigateTo",
          handler: handleNavigateTo,
        },
        {
          event: "SendToGA",
          handler: handleSendToGA,
        },
        {
          event: "NativeLogOut",
          handler: handleNativeLogOut,
        },
        {
          event: "PromptAvatarUpload",
          handler: handlePromptAvatarUpload,
        },
        {
          event: "PromptBulletinImageUpload",
          handler: handlePromptBulletinImageUpload,
        },
        {
          event: "ResetFocusStatus",
          handler: handleResetFocusStatus,
        },
        {
          event: "ResetHlsPlayer",
          handler: handleResetHlsPlayer,
        },
        {
          event: "ShowLiveSupportWidget",
          handler: handleShowLiveSupportWidget,
        },
        {
          event: "SetUnityReady",
          handler: handleSetUnityReady,
        },
      ];

      // register the event listeners on side effect
      eventListenerPairs.forEach((pair) => {
        unityContextHook.addEventListener(pair.event, pair.handler);
      });

      // cleanup the event listeners when the component unmounts to prevent memory leaks
      return () => {
        eventListenerPairs.forEach((pair) => {
          unityContextHook.removeEventListener(pair.event, pair.handler);
        });
      };
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  useEffect(() => {
    // Unity is ready and the context is correctly loaded, let unity know that
    // we're good to go.
    if (isUnityReady && unityContextHook.isLoaded) {
      unityContextHook.sendMessage("BrandbaseController", "FrontEndReady");
    }
    // eslint-disable-next-line
  }, [isUnityReady, unityContextHook.isLoaded]);

  const CloseModal = useCallback(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, [unityContextHook]);

  //Custom Curiious Events.
  const handleOpenNewTab = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      const url = unityParam as string;
      OpenNewTab(url);
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const handleNavigateTo = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      //For convenience, we want to preserve the url parameters if there are any.
      const url = unityParam as string;
      var queryString = window.location.search;

      navigate(url + queryString, { replace: true });
    },
    // eslint-disable-next-line
    [unityContextHook]
  );
  const handleSendString = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      // window.open(url, '_blank');
      const message = unityParam as string;
      console.log("Im using this button");
      PrintString(message);
      setImageUploaderType("Bulletin");
      setIsOpen(true);
      // console.log('hello world!')
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const handleThrowError = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      //How we receive error messages from unity.
      console.log("Received an error message from unity");
      const errorMessage = unityParam as string;
      console.error(errorMessage);
      setErrorMessageText(errorMessage);
      setIsError(true);
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const handleConvertDatetimeToUTC = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      // function handleConvertDatetimeToUTC(yr:number, mnth:number, dy:number, hr:number, min:number, tz:string): string
      const p = unityParam as any;

      return ConvertDatetimeToUTC(
        p.yr as number,
        p.mnth as number,
        p.dy as number,
        p.hr as number,
        p.min as number,
        p.tz as string
      );
    },
    // eslint-disable-next-line
    [unityContextHook]
  );
  const handleSendToGA = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      // function handleSendToGA(eventname: string, eventdata: string): void
      const p = unityParam as any;
      if (unityParam) {
        SendGAEvent(p.eventname as string, p.eventdata as string);
      }
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const handleNativeLogOut = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      //When someone logs out, we want to return them to this instance. Let's build a path for that.
      if (instanceContext?.instanceState) {
        console.log("Unity is requesting a logout");

        const logoutOptions: LogoutOptions = {
          logoutParams: {
            returnTo: window.location.origin,
          },
        };

        logout(logoutOptions);
      }
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const handlePromptAvatarUpload = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      setImageUploaderType("Avatar");
      setModalTitle("Upload a Profile Picture");
      setIsOpen(true);
      setMaxImageUploads(1);
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  const handlePromptBulletinImageUpload = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      //We can either put in a prepolulated list of images here, or pass in null for it to be empty
      const allowedUploads = unityParam as number;
      setImageUploaderType("Bulletin");
      setModalTitle("Upload Story Images");
      setIsOpen(true);
      setMaxImageUploads(allowedUploads);
      console.log(`Setting uploader allowing ${allowedUploads} uploads`);
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  // Focus handling. This detects the first focus or click event.
  //We're interested in this because it lets use usefully trigger audio in video streams
  //not having focus causes problems here
  useEffect(() => {
    const handleFocus = () => {
      console.log("Curiious has focus");
      SendFocusEventToUnity();
    };
    // const handleBlur = () =>
    // {
    //     console.log('Tab lost focus');
    // };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
      //window.removeEventListener('blur', handleBlur);
    };
  });

  const SendFocusEventToUnity = useCallback(() => {
    if (!focusStatus) {
      console.log("Sent Unity a window focus/click event.");
      unityContextHook.sendMessage("BrandbaseController", "WindowFocusEvent");
      setFocusStatus(true);
    }
    // eslint-disable-next-line
  }, [unityContextHook]);

  const handleResetFocusStatus = useCallback(() => {
    //When we swap between worlds, we need to reset the focus status so we can
    //detect the first click again.
    console.log("Resetting focus status on Unity's request");
    setFocusStatus(false);
    // eslint-disable-next-line
  }, [unityContextHook]);

  const handleResetHlsPlayer = useCallback(() => {
    //Resets the HLS player, this works around issues with it not resetting in between
    //streams, when jumping between them in unity.
    console.log("Resetting HLS player...");
    window.hls.destroy();
    window.hls = new Hls();
    // eslint-disable-next-line
  }, [unityContextHook]);

  const handleShowLiveSupportWidget = useCallback(() => {
    //Triggers and shows the live support widget.
    //TODO: how do we talk to the widget when it is so many components higher than
    //this one?
    console.log("React is showing the live support widget");
    let help = document.getElementById("chat-widget-container");
    if (help) {
      console.log("Open Help");
      help.style.visibility = "visible";
      instanceContext.setHelpVisibility("maximized");
    }
    // eslint-disable-next-line
  }, [unityContextHook]);

  const handleSetUnityReady = useCallback(
    (unityParam: ReactUnityEventParameter) => {
      //We can either put in a prepolulated list of images here, or pass in null for it to be empty
      const unityReadyParam = unityParam as number;

      // cast the string to boolean handling upper/lower case versions of true
      var unityReady: boolean = unityReadyParam === 1;
      console.log(`Unity Ready status: ${unityReady}`, { unityParam });
      setIsUnityReady(unityReady);
    },
    // eslint-disable-next-line
    [unityContextHook]
  );

  //Custom CSS
  const loadingBox: CSSProperties = {
    maxWidth: "90%",
    width: "375px",
    height: "15px",
    border: "2px solid",
    borderRadius: "15px",
    marginTop: "2.5vh",
    marginBottom: "2.5vh",
    borderColor: baseColor ? baseColor : "#99a1a7",
  };

  const loadingBar: CSSProperties = {
    backgroundColor: highlightColor ? highlightColor : "#363635", //give the loading bar a highlight if necessary.
    height: "100%",
    width: unityContextHook.loadingProgression * 100 + "%",
    borderRadius: "15px",
  };

  const unityContainer: CSSProperties = {
    position: "fixed",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    overflow: "auto",
    zIndex: 1,
  };

  const unityCanvas: CSSProperties = {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    zIndex: "10",
  };

  const toggleModal = () => setIsOpen(!modalIsOpen);

  if (!isError) {
    return (
      <Fragment>
        {!unityContextHook.isLoaded && (
          <Fragment>
            <div style={loadingBox}>
              <div style={loadingBar}></div>
            </div>
          </Fragment>
        )}
        <div
          onClick={SendFocusEventToUnity}
          style={unityContainer}
          hidden={!unityContextHook.isLoaded}
          id="unity-container"
        >
          {showGuestCTA && <GuestCallToAction />}
          <CuriiousLightbox unityContextHook={unityContextHook} />
          <Modal isOpen={modalIsOpen} centered backdrop="static">
            <ModalHeader toggle={toggleModal}>{modalTitle}</ModalHeader>
            <ModalBody>
              <UppyImageUploader
                closeFunction={CloseModal}
                unityContextHook={unityContextHook}
                uploaderType={imageUploaderType}
                maxUploads={maxImageUploads}
              />
            </ModalBody>
          </Modal>
          <StateWrangler unityContextHook={unityContextHook} />
          <Unity
            style={unityCanvas}
            unityProvider={unityContextHook.unityProvider}
            devicePixelRatio={devicePixelRatio}
          />
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <ErrorMessage
          errorType={"error"}
          errorText={errorMessageText}
          centreOnPage={false}
        />
      </Fragment>
    );
  }
}
