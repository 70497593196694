import React, { Fragment, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { WorldContext } from "./CuriiousStateContextProvider";

export default function WorldLoader() {
  let params = useParams();
  const worldContext = useContext(WorldContext);

  //This should only happen on initial load
  useEffect(() => {
    //Check for a world in the URL bar.
    var worldId = Number(params.worldId);

    if (worldId && worldId !== worldContext?.worldState) {
      console.log(`Found World ID ${worldId} in url parameters`);
      worldContext?.setWorldState(worldId);
    }
    // eslint-disable-next-line
  }, [worldContext, params]);

  return <Fragment></Fragment>;
}
